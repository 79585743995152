import React, { useEffect, useState } from "react";
import { Card, Spinner, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";
import { Col, Icon, DataTablePagination } from "../../Component";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import Moment from "react-moment";
import { role, user_id, username } from "../../../redux/userSlice";
import { toast } from "react-toastify";
import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import "jspdf-autotable";

const TicketStatusMetrics = ({ dateRanges, actions }) => {
  const userrole = useSelector(role);
  const clientValueId = localStorage.getItem("value");
  const client_id = useSelector(user_id);
  const logoUrl = process.env.REACT_APP_SIGN_IN;
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketCounts, setTicketCounts] = useState([]);
  const [rowsPerPageS, setRowsPerPage] = useState(7);
  const [loading, setLoading] = useState(true);
  const [clientname, setClientname] = useState("");
  const clientValueLabel = localStorage.getItem("label");
  const user = useSelector(username);

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const exportNoDataAlert = () => {
    toast.error("There is no data available for export!", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
    });
  };

  const exportSuccessMsg = () => {
    toast.dark("Chart Report Exported Successfully", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      icon: false,
    });
  };

  const exportExcel = () => {
    if (ticketCounts.length === 0) {
      exportNoDataAlert();
      return;
    }
    const fileName = `Ticket Status [${formatDate(dateRanges.start)} - ${formatDate(dateRanges.end)}]`;
    const exportedData = ticketCounts.map((row) => ({
      Date: row.date,
      Activated: row.activated,
      Unactivated: row.unactivated,
      Validated: row.validated,
      Invalidated: row.unvalidated,
      Expired: row.expired,
      Total: row.total,
    }));
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data: exportedData, fileName, exportType });
    exportSuccessMsg();
  };

  const exportPDF = () => {
    if (ticketCounts.length === 0) {
      exportNoDataAlert();
      return;
    }
    console.log("Export data:", ticketCounts);
    const fileName = `Ticket Status [${formatDate(dateRanges.start)} - ${formatDate(dateRanges.end)}]`;
    const doc = new jsPDF("landscape");

    const logo = new Image();
    logo.crossOrigin = "anonymous";
    logo.src = logoUrl;

    logo.onload = () => {
      const imgWidth = 30;
      const imgHeight = 30;
      const logoX = doc.internal.pageSize.width - imgWidth - 10;
      const logoY = 2;
      doc.addImage(logo, "JPEG", logoX, logoY, imgWidth, imgHeight);

      doc.setFont("times", "bold");
      doc.setFontSize(20);
      doc.setTextColor(255, 0, 0);
      doc.text(clientname, 10, 20);

      doc.setFont("times", "normal");
      doc.setFontSize(16);
      doc.setTextColor(0);
      doc.text("Ticket Status", 10, 40);

      const tableData = ticketCounts.map((row) =>
        exportColumns.map((col) => {
          const selectorResult = col.selector(row);
          if (typeof col.selector === "function") {
            return selectorResult;
          } else {
            return row[col.selector];
          }
        })
      );

      doc.autoTable({
        head: [exportColumns.map((col) => col.name)],
        body: tableData,
        startY: 50,
      });

      doc.save(fileName);
    };

    logo.onerror = () => {
      console.error("Failed to load logo image");

      doc.setFont("times", "normal");
      doc.setFontSize(16);
      doc.setTextColor(0);
      doc.text("Ticket Status", 10, 10);

      const tableData = ticketCounts.map((row) =>
        exportColumns.map((col) => {
          const selectorResult = col.selector(row);
          if (typeof col.selector === "function") {
            return selectorResult;
          } else {
            return row[col.selector];
          }
        })
      );

      doc.autoTable({
        head: [exportColumns.map((col) => col.name)],
        body: tableData,
        startY: 20,
      });

      doc.save(fileName);
    };
  };

  useEffect(() => {
    if (userrole === 3) {
      setClientname(clientValueLabel);
    } else {
      setClientname(user);
    }
  }, [clientValueLabel]);

  useEffect(() => {
    const fetchTicketCounts = async () => {
      try {
        let response;
        if (userrole === 3 && clientValueId) {
          response = await fetch(
            `${process.env.REACT_APP_CONFIG_BASE_URL}GetDaywiseTicketCount?startDate=${formatDate(
              dateRanges.start
            )}&endDate=${formatDate(dateRanges.end)}&client_id=${clientValueId}`
          );
        } else {
          response = await fetch(
            `${process.env.REACT_APP_CONFIG_BASE_URL}GetDaywiseTicketCount?startDate=${formatDate(
              dateRanges.start
            )}&endDate=${formatDate(dateRanges.end)}&client_id=${client_id}`
          );
        }

        const data = await response.json();

        // Sort data by date in descending order by default
        const sortedData = data.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setTicketCounts(sortedData);
      } catch (error) {
        setTicketCounts([]);
        console.error("Error fetching ticket counts:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTicketCounts();
  }, [clientValueId, dateRanges.start, dateRanges.end, client_id, userrole]);

  // const handleSort = (column, sortDirection) => {
  //   const sortedData = [...ticketCounts].sort((a, b) => {
  //     if (column.selector === "date") {
  //       return sortDirection === "asc" ? new Date(a.date) - new Date(b.date) : new Date(b.date) - new Date(a.date);
  //     } else if (column.selector === "activated") {
  //       return sortDirection === "asc" ? a.activated - b.activated : b.activated - a.activated;
  //     } else if (column.selector === "unactivated") {
  //       return sortDirection === "asc" ? a.unactivated - b.unactivated : b.unactivated - a.unactivated;
  //     } else if (column.selector === "validated") {
  //       return sortDirection === "asc" ? a.validated - b.validated : b.validated - a.validated;
  //     } else if (column.selector === "unvalidated") {
  //       return sortDirection === "asc" ? a.unvalidated - b.unvalidated : b.unvalidated - a.unvalidated;
  //     } else if (column.selector === "expired") {
  //       return sortDirection === "asc" ? a.expired - b.expired : b.expired - a.expired;
  //     } else if (column.selector === "total") {
  //       return sortDirection === "asc" ? a.total - b.total : b.total - a.total;
  //     }
  //     return new Date(a.date) - new Date(b.date);
  //   });
  //   setTicketCounts(sortedData);
  // };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => new Date(row.date),
      sortable: true,
      sortFunction: (a, b) => new Date(a.date) - new Date(b.date),
      cell: (row) =>
        new Date(row.date).getFullYear() >= 2001 ? <Moment format="MMMM Do YYYY">{row.date}</Moment> : "-",
      grow: 2,
    },
    {
      name: "Activated",
      selector: (row) => row.activated,
      sortable: true,
      sortFunction: (a, b) => a.activated - b.activated,
      grow: 1.5,
    },
    {
      name: "Unactivated",
      selector: (row) => row.unactivated,
      sortable: true,
      sortFunction: (a, b) => a.unactivated - b.unactivated,
      grow: 1.5,
    },
    {
      name: "Validated",
      selector: (row) => row.validated,
      sortable: true,
      sortFunction: (a, b) => a.validated - b.validated,
      grow: 1.5,
    },
    {
      name: "Invalidated",
      selector: (row) => row.unvalidated,
      sortable: true,
      sortFunction: (a, b) => a.unvalidated - b.unvalidated,
      grow: 1.5,
    },
    {
      name: "Expired",
      selector: (row) => row.expired,
      sortable: true,
      sortFunction: (a, b) => a.expired - b.expired,
      grow: 1.5,
    },
    {
      name: "Total",
      selector: (row) => row.total,
      sortable: true,
      sortFunction: (a, b) => a.total - b.total,
      grow: 1.5,
    },
  ];

  return (
    <React.Fragment>
      <Col xxl="12">
        <Card className="card-full">
          <div className="card-inner">
            <div className="d-flex flex-row align-items-start justify-content-between">
              <div className="card-title">
                <h6 className="title">Ticket Status</h6>
              </div>
              <div className="card-tools">
                <UncontrolledDropdown>
                  <DropdownToggle tag="div" color="transparent">
                    <a
                      href="#toggle"
                      onClick={(ev) => ev.preventDefault()}
                      className="btn btn-danger btn-dim d-none d-sm-inline-flex"
                    >
                      <Icon className="d-none d-sm-inline" name="download-cloud" />
                    </a>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <DropdownItem
                          onClick={(e) => {
                            e.preventDefault();
                            exportExcel();
                          }}
                          tag="a"
                          href="#dropdownitem"
                          onMouseEnter={(e) => e.target.classList.add("text-danger")}
                          onMouseLeave={(e) => e.target.classList.remove("text-danger")}
                        >
                          <span>Export as Excel</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          onClick={(e) => {
                            e.preventDefault();
                            exportPDF();
                          }}
                          tag="a"
                          href="#dropdownitem"
                          onMouseEnter={(e) => e.target.classList.add("text-danger")}
                          onMouseLeave={(e) => e.target.classList.remove("text-danger")}
                        >
                          <span>Export as PDF</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
            {loading ? (
              <Card>
                <div className="nk-ecwg nk-ecwg6">
                  <div className="card-inner">
                    <div className="card-title-group" style={{ display: "flex", justifyContent: "center" }}>
                      <div>
                        <Spinner color="primary" />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            ) : (
              <DataTable
                data={ticketCounts}
                columns={columns}
                noDataComponent={<div className="pt-5 pb-5 text-center">No Ticket Data Found</div>}
                sortIcon={
                  <div>
                    <span>&darr;</span>
                    <span>&uarr;</span>
                  </div>
                }
                defaultSortField="date"
                //defaultSortAsc={false}
                pagination
                paginationTotalRows={ticketCounts.length}
                paginationPerPage={rowsPerPageS}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                //onSort={handleSort}
                //sortServer={true}
                paginationComponent={({ currentPage, rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage }) => (
                  <DataTablePagination
                    customItemPerPage={rowsPerPageS}
                    itemPerPage={rowsPerPage}
                    totalItems={rowCount}
                    paginate={onChangePage}
                    currentPage={currentPage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                  />
                )}
              />
            )}
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
};
const exportColumns = [
  {
    name: "Date",
    selector: (row) => row.date,
    sortable: true,
  },
  {
    name: "Activated",
    selector: (row) => row.activated,
    sortable: true,
  },
  {
    name: "Unactivated",
    selector: (row) => row.unactivated,
    sortable: true,
  },
  {
    name: "Validated",
    selector: (row) => row.validated,
    sortable: true,
  },
  {
    name: "Invalidated",
    selector: (row) => row.unvalidated,
    sortable: true,
  },
  {
    name: "Expired",
    selector: (row) => row.expired,
    sortable: true,

    sortField: "ActivatedDate",
  },
  {
    name: "Total",
    selector: (row) => row.total,
    sortable: true,
  },
];
export default TicketStatusMetrics;
