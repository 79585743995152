import React, { useState, useEffect, useRef } from "react";
import Content from "../layout/content/Content";
import "moment-timezone";
import { Nav, NavItem, NavLink, TabContent, TabPane, FormGroup, Label, ButtonGroup, Badge } from "reactstrap";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { SketchPicker } from "react-color";
import { toast } from "react-toastify";
import Head from "../layout/head/Head";
import {
  PreviewCard,
  Block,
  BlockHead,
  BlockTitle,
  BlockBetween,
  BlockHeadContent,
  Icon,
  Row,
  Col,
  Button,
  PaginationComponent,
} from "../components/Component";
import { Alert, UncontrolledAlert } from "reactstrap";
import { UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { user_id } from "../redux/userSlice";
import { fetchClientInfo } from "../redux/sessionClientSlice";
import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Input,
  Spinner,
} from "reactstrap";
import axios from "axios";
import { useForm } from "react-hook-form";
import "./total.css";
const successAlert = () => {
  Swal.fire({
    icon: "success",
    title: "Success",
    text: "Agency updated successfully",
  });
};
const successAuthAlert = () => {
  Swal.fire({
    icon: "success",
    title: "Success",
    text: "Authenticated successfully",
  });
};
const CloseButton = () => {
  return (
    <span className="btn-trigger toast-close-button" role="button">
      <Icon name="cross"></Icon>
    </span>
  );
};
const CustomAuthToast = () => {
  return (
    <div className="toastr-text">
      <h5>Authenticated Successfully</h5>
      <p>Your have Access to update.</p>
    </div>
  );
};
const messageAuthToast = () => {
  toast.success(<CustomAuthToast />, {
    position: "bottom-right",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    closeButton: <CloseButton />,
  });
};

const failureAlert = () => {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Something went wrong",
    showConfirmButton: false,
    timer: 1500,
  });
};
const failureAuthAlert = () => {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Authentication Failed",
    showConfirmButton: false,
    timer: 1500,
  });
};

const defValues = {
  a_macaddress: "",
  averagerssivalue: "10",
  distancetimes: "1",
  powervalue: "20",
  measurepower: "-60",
  distance: "3.2808",
  tx_power: "20",
  ble_rssi_4: "1",
  ble_rssi_5a: "1",
  ble_rssi_5b: "3",
  ble_rssi_5c: "3",
  menuitem: "1,2,3,4,5",
  qos: "10",
  iosversionnumber: "10",
  androidversionnumber: "10",
  cardnox_sandbox_key: "irfanlesnardevb3bc53ce02344043b7cdf9161f4fe73",
  cardnox_live_key: "zigf319b96bc1464aef9a0b566d4f9f2dae",
  forceupdatedescription: "We have added new features and fixed some bugs to make your experience seamless",
  forceupdatetitle: "App Update Required",
  order_confirmed_title: "Order Confirmed",
  order_confirmed_subtitle: "Board your vehicle with your phone in your pocket!",
  frequency_interval_line: "10000",
  frequency_interval_realtime_bus: "10000",
  here_api_key: "DER6RxEoaCemAWyXJTeWVrU0GJSiwR28vYACdQw7vv0",
  permission_title: "Need Permissions",
  permission_message: "These permissions are required to enable this functionality.",
  permission_description:
    "These permissions are required before you arrive at the venue or your tickets will not be processed.",
  process_noise: "0.01",
  measurement_noise: "1.0",
  formula_status: 1,
  ai_feature: true,
  speech_to_text: 1,
  showinui: false,
  ...(process.env.REACT_APP_PANEL_TEXT === "ZIG" && { capefare: 0, capeamount: 0, capperiod: 0 }),
};

const AllVenues = () => {
  const [formData, setFormData] = useState(defValues);
  const [authFormData, setAuthFormData] = useState({});

  const [ischecked, setischecked] = useState(true);

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const client_id = useSelector(user_id);
  const initialData = useRef([]);
  const [onSearchText, setSearchText] = useState("");
  const [sm, updateSm] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(7);
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const filteredItemsNew = client_id === 52 ? currentItems.filter((item) => item.clientid === 52) : currentItems;
  //Config Data
  const [configData, setConfigData] = useState([]);
  const currentConfigData = configData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const { errors, register, handleSubmit } = useForm();
  const [view, setView] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [approvedChecked, setApprovedChecked] = useState(false);
  const [pendingChecked, setPendingChecked] = useState(false);
  const [enabledChecked, setEnabledChecked] = useState(false);
  const [readOnlyChecked, setreadOnlyChecked] = useState(false);
  const [showInUI, setshowInUI] = useState(true);

  //Enabled smart agancy
  const [enabledAgencyId, setEnabledAgencyId] = useState(0);
  const [enabledAgencyName, setEnabledAgencyName] = useState("");
  const [configFailureAlert, setConfigFailureAlert] = useState(false);
  const [configSuccessAlert, setConfigSuccessAlert] = useState(false);

  //Fare Capping
  const [capperiod, setCapPeriod] = useState("0");
  const [capefare, setCapFare] = useState("0");
  //Update amount dynamically
  const [fareAmt, setFareAmt] = useState(0);
  const [cappingFares, setCappingFares] = useState([]);

  // const handleFareChange = (e) => {
  //   const selectedFareID = e.target.value;
  //   console.log("selectedFareID", selectedFareID);
  //   if (selectedFareID) {
  //     if (selectedFareID === 0) {
  //       setFareAmt(0);
  //       return;
  //     }
  //     const selectedFare = cappingFares.find((fare) => fare.fareid === selectedFareID);
  //     setFareAmt(selectedFare.fareamount);
  //     setFormData({ ...formData, capefare: selectedFare.fareid });
  //   }
  // };

  // const capFareInputChange = (e) => {
  //   setCapFare(e.target.value);
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const capIntervalInputChange = (e) => {
  //   if (e.target.value === 0) {
  //     setCapFare("0");
  //     setFareAmt(0);
  //   }
  //   setCapPeriod(e.target.value);
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  const handleApprovedChange = (isEdited) => {
    if (isEdited) {
      setApprovedChecked(true);
      let filteredData = [...initialData.current];
      filteredData = filteredData.filter((item) => item.status);

      setData(filteredData);
      setCurrentPage(1);
    } else {
      setApprovedChecked(!approvedChecked);
    }
  };
  const handlePendingChange = () => {
    setPendingChecked(!pendingChecked);
  };
  const handleApprovedChange2 = () => {
    setEnabledChecked(!enabledChecked);
  };
  const handlePendingChange2 = () => {
    setreadOnlyChecked(!readOnlyChecked);
  };
  const getRssiValueFromFt = (x) => {
    return -45 - 5 * x;
  };
  const getFtFromRssiValue = (y) => {
    let num = ((y + 45) / -5).toFixed(2);
    return isNaN(num) ? 3 : num;
  };
  const onFormCancel = () => {
    setView(false);
    resetForm();
  };
  const onAuthFormCancel = () => {
    setShowAuthModal(false);
    setAuthFormData({});
  };
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onAuthInputChange = (e) => {
    setAuthFormData({ ...authFormData, [e.target.name]: e.target.value });
    // console.log(authFormData);
  };

  const resetForm = () => {
    setFormData({});
  };
  const onFilterChange = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    if (e.nativeEvent.inputType === "deleteContentBackward" && searchText.length === 0) {
      setData([...initialData.current]);
    } else {
      const filteredData = initialData.current.filter((item) =>
        item.clientname.toLowerCase().includes(searchText.toLowerCase())
      );
      setData(filteredData);
    }
  };

  //Fare Capping Get Fares
  const getCapFares = async (clientid) => {
    try {
      let response;
      response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getFarebyAgency?agencyid=` + clientid);
      setCappingFares([...response.data]);
      console.log("Cap fares", response.data);
      return [...response.data];
    } catch (err) {
      console.log(err);
    }
  };

  const onEditClick = (id) => {
    console.log("Client's id", id);
    const selected = data.find((i) => i.clientid === id);
    console.log("selected client", selected);

    if (process.env.REACT_APP_PANEL_TEXT === "ZIG") {
      //Update cap amount state
      setFareAmt(selected?.capeamount);
      console.log("Client id", selected?.clientid);
      getCapFares(selected?.clientid);

      const selected2 = { ...selected };
      delete selected2.clientid;
      delete selected2.Macaddresslist;
      delete selected2.Activatedate;
      delete selected2.createddate;
      delete selected2.Payment;

      setFormData({
        ...selected2,
        biborssi_ios:
          selected?.bibobrssi_ios && selected?.bibobrssi_ios.length === 0
            ? selected?.bibobrssi
            : selected?.bibobrssi_ios,
        bibobrssi_ios:
          selected?.bibobrssi_ios && selected?.bibobrssi_ios.length === 0
            ? selected?.bibobrssi
            : selected?.bibobrssi_ios,
        clientprimarycolorcode: selected?.clientprimarycolorcode ?? "#333333",
        clientsecondaryarycolorcode: selected?.clientsecondaryarycolorcode ?? "#333333",
        primarycolorcode: selected?.primarycolorcode ?? "#333333",
        secondarycolorcode: selected?.secondarycolorcode ?? "#333333",
        activateStatus: selected?.Activatestatus,
        a_macaddress: selected?.a_macaddress ?? "",
        id: selected?.clientid,
      });
    } else {
      const clientID = selected?.clientid;
      delete selected?.clientid;
      delete selected?.Macaddresslist;
      delete selected?.Activatedate;
      delete selected?.createddate;
      delete selected?.Payment;
      console.log("mdot selected", selected);
      setFormData({
        ...selected,
        biborssi_ios:
          selected?.bibobrssi_ios && selected?.bibobrssi_ios.length === 0
            ? selected?.bibobrssi
            : selected?.bibobrssi_ios,
        bibobrssi_ios:
          selected?.bibobrssi_ios && selected?.bibobrssi_ios.length === 0
            ? selected?.bibobrssi
            : selected?.bibobrssi_ios,
        clientprimarycolorcode: selected?.clientprimarycolorcode ?? "#333333",
        clientsecondaryarycolorcode: selected?.clientsecondaryarycolorcode ?? "#333333",
        primarycolorcode: selected?.primarycolorcode ?? "#333333",
        secondarycolorcode: selected?.secondarycolorcode ?? "#333333",
        activateStatus: selected?.Activatestatus,
        a_macaddress: selected?.a_macaddress ?? "",
        id: clientID,
      });
    }
    setView(true);
  };

  const checkAuthentication = async () => {
    // if (activeTab && !isAuthenticated) {
    setShowAuthModal(true);
    //   console.log("Not Authenticated, Please Authenticate");
    //   return false;
    // }

    console.log("Authenticated!");
    //return true;
  };

  const onAuthSubmit = () => {
    //console.log(authFormData.userName);
    if (
      authFormData.userName.toLowerCase() === "Karthik@zed.digital".toLowerCase() &&
      authFormData.userPasscode === "Polgara!@12"
    ) {
      console.log("Authenticated Successfully");
      setShowAuthModal(false);
      messageAuthToast();
      onEditSubmit();
    } else {
      console.log("Authentication has Failed");
      failureAuthAlert();
    }
  };

  const onEditSubmit = async () => {
    console.log("formData", formData);
    let submittedData = {
      ...formData,

      freeticket: formData.freeticket ? 1 : 0,
      //biborssi_ios: formData.bibobrssi_ios,
      rssvalue: "-60",
      rssi_ios: "-60",
      bibobrssi_ios: "-60",
      biborssi_ios: "-60",
      bibobrssi: "-60",
      beverageinstructiontext:
        "Purchase you favorite beverage and proceed to counter and wait for your turn where you can get your ordered items and enjoy it.",
      beveragetitle: "Food/Beverage",
      showinui: showInUI,
      ...(process.env.REACT_APP_PANEL_TEXT === "ZIG" && {
        capperiod: 0,
        capefare: 0,
        capeamount: 0,
        enable: formData.enable,
        cap_period: formData.cap_period,
        fare_type: formData.fare_type,
        beverage_averagerssivalue: formData.beverage_averagerssivalue,
        beverage_background_feet_end: formData.beverage_background_feet_end,
        beverage_background_feet_start: formData.beverage_background_feet_start,
        beverage_distance: formData.beverage_distance,
        beverage_foreground_feet_end: formData.beverage_foreground_feet_end,
        beverage_foreground_feet_start: formData.beverage_foreground_feet_start,
        beverage_measure_value: formData.beverage_measure_value,
        beverage_tx_power: formData.beverage_tx_power,
        ibeacon_beverage_status_android: formData.ibeacon_beverage_status_android,
        ibeacon_beverage_status_ios: formData.ibeacon_beverage_status_ios,
      }),
    };

    // for (let key in submittedData) {
    //   if (!isNaN(parseInt(key))) {
    //     delete submittedData[key];
    //   }
    // }

    const response = await axios.put(`${process.env.REACT_APP_CONFIG_BASE_URL}config`, { ...submittedData });
    if (response.status === 200) {
      const newData = [...data];
      const index = newData.findIndex((item) => item.id === formData.id); // Assuming 'id' is the unique identifier of your data entries
      newData[index] = submittedData;
      setData(newData);
      onFormCancel();
      successAlert();
      setTimeout(function () {
        client_id === 52 && window.location.reload();
      }, 1000);

      //Reset client value and labels
      dispatch(fetchClientInfo());
      sessionStorage.setItem("AgencyUpdated", true);

      //Update and reload states
      getMuseumData();
    } else {
      failureAlert();
    }
  };

  //Sorting

  const [sortOrder, setSortOrder] = useState("desc");
  const [sortOrder2, setSortOrder2] = useState("desc");

  const renderSortIcon = () => {
    if (sortOrder === "asc") {
      return <Icon name="arrow-up" />;
    } else if (sortOrder === "desc") {
      return <Icon name="arrow-down" />;
    }
  };

  const renderSortIcon2 = () => {
    if (sortOrder2 === "asc") {
      return <Icon name="arrow-up" />;
    } else if (sortOrder2 === "desc") {
      return <Icon name="arrow-down" />;
    }
  };

  const handleSort = (columnName) => {
    const sortedData = [...data].sort((a, b) => {
      if (columnName === "createddate") {
        const sortOrderToggle = sortOrder === "asc" ? "desc" : "asc";
        setSortOrder(sortOrderToggle);
        setSortOrder2("desc");
        if (sortOrderToggle === "asc") {
          return new Date(a[columnName]) - new Date(b[columnName]);
        } else {
          return new Date(b[columnName]) - new Date(a[columnName]);
        }
      } else if (columnName === "clientname") {
        const sortOrderToggle = sortOrder2 === "asc" ? "desc" : "asc";
        setSortOrder2(sortOrderToggle);
        setSortOrder("desc");
        return sortOrderToggle === "asc"
          ? a[columnName].localeCompare(b[columnName])
          : b[columnName].localeCompare(a[columnName]);
      } else {
        // For other columns, maintain the existing sorting logic
        return a[columnName] > b[columnName] ? 1 : -1;
      }
    });
    setData(sortedData);
    setCurrentPage(1);
  };

  useEffect(() => {
    //Reset sort icons
    setSortOrder("desc");
    setSortOrder2("desc");
  }, [approvedChecked, pendingChecked, readOnlyChecked, enabledChecked, onSearchText]);

  const tabWiseErrors = [0, 0, 0, 0, 0];
  if (Object.entries(errors).length > 0) {
    window.scrollTo(100, 100);
    if (
      errors?.clientname ||
      errors?.clientuuid ||
      errors?.clientlat ||
      errors?.clientlng ||
      errors?.radius ||
      errors?.serviceinterval
    )
      tabWiseErrors[0] = 1;
    if (errors?.freeticket || errors?.scaninterval || errors?.miscfee || errors?.Message) tabWiseErrors[1] = 1;
    if (errors?.clientimage || errors?.clientdesc) tabWiseErrors[3] = 1;
    if (errors?.bibobrssi_ios || errors?.rssi_ios || errors?.bibobrssi || errors?.rssvalue) tabWiseErrors[4] = 1;
  }
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = initialData.current.filter((item) => {
        return item?.clientname.toLowerCase().includes(onSearchText.toLowerCase());
      });
      setData([...filteredObject]);
      if (approvedChecked) {
        handleApprovedChange();
      }
      if (pendingChecked) {
        handlePendingChange();
      }
      if (readOnlyChecked) {
        handleApprovedChange2();
      }
      if (enabledChecked) {
        handlePendingChange2();
      }
    } else {
      setData([...initialData.current]);
    }
    setCurrentPage(1);
  }, [onSearchText]);

  const getMuseumData = async (isEdited) => {
    setLoading(true);
    const response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}config`);

    if (response.status === 200) {
      // setData([...response.data]);
      console.log("data values", response.data);
      initialData.current = [...response.data];

      // setCurrentPage(1);
      setSearchText("");

      //Show active data's initially
      // if (!approvedChecked) {
      //   handleApprovedChange();
      // }

      handleApprovedChange(true);

      //Find enabled smart agency id and name
      const smartVenueObject = response.data.find((obj) => obj.smart_venues === true);

      if (smartVenueObject) {
        setEnabledAgencyId(smartVenueObject.id);
        setEnabledAgencyName(smartVenueObject.clientname);
      } else {
        console.log("No smart venue has been enabled.");
      }
    } else throw new Error();
    setLoading(false);
  };

  useEffect(() => {
    try {
      getMuseumData();
    } catch (err) {
      setLoading(false);
      setError(true);
    }
  }, []);

  useEffect(() => {
    let filteredData = [...initialData.current];

    if (approvedChecked && !pendingChecked) {
      filteredData = filteredData.filter((item) => item.status);
    } else if (!approvedChecked && pendingChecked) {
      filteredData = filteredData.filter((item) => !item.status);
    }

    if (enabledChecked && !readOnlyChecked) {
      filteredData = filteredData.filter((item) => item.smart_venues);
    } else if (!enabledChecked && readOnlyChecked) {
      filteredData = filteredData.filter((item) => !item.smart_venues);
    }

    setData(filteredData);
    setCurrentPage(1);
  }, [approvedChecked, pendingChecked, enabledChecked, readOnlyChecked]);

  //Config data
  useEffect(() => {
    const finalValues = data.map((item) => {
      // Check if all conditions are met for the current object
      const condition1 = item.tx_power === "20";
      const condition2 = item.distance === "3.2808";
      const condition3 = item.measurepower === "-60";
      const condition4 = item.powervalue === "20";
      const condition5 = item.ble_rssi_4 === "1";
      const condition6 = item.ble_rssi_5c === "4";
      const condition7 = item.scaninterval === 3000;
      const condition8 = item.ble_rssi_5a === "1";
      const condition9 = item.ble_rssi_5b === "3";
      const condition10 = item.averagerssivalue === "5";
      const condition11 = item.serviceinterval === 1000;
      const condition12 = item.clienttype === 8;
      const condition13 = item.service_enable === true;
      const condition14 = item.status === 1;
      const condition15 = item.formula_status === 4;
      const condition16 = item.threshold === 500;
      const condition17 = item.sendoutdata === true;
      const condition18 = item.process_noise === "0.01";
      const condition19 = item.measurement_noise === "1.0";
      const condition20 = item.scanintervalo === 10000;
      const condition21 = item.validationmode === 1;
      const condition22 = item.validationmode_b === 1;
      const condition23 = item.ibeacon_status === 2;
      const condition24 = item.force_permission === true;
      const condition25 = item.forceupdate === true;
      const condition26 = item.sos === true;
      const condition27 = item.stop === true;

      if (item.smart_venues === true) {
        if (
          condition1 &&
          condition2 &&
          condition3 &&
          condition4 &&
          condition5 &&
          condition6 &&
          condition7 &&
          condition8 &&
          condition9 &&
          condition10 &&
          condition11 &&
          condition12 &&
          condition13 &&
          condition14 &&
          condition15 &&
          condition16 &&
          condition17 &&
          condition18 &&
          condition19 &&
          condition20 &&
          condition21 &&
          condition22 &&
          condition23 &&
          condition24 &&
          condition25 &&
          condition26 &&
          condition27
        ) {
          setConfigSuccessAlert(true);
          setConfigFailureAlert(false);
          return 1;
        } else {
          setConfigFailureAlert(true);
          setConfigSuccessAlert(false);
          return 0;
        }
      } else {
        return 0;
      }

      // return condition1 && condition2 && condition3 && condition4 && condition5 && condition6 && condition7 && condition8 && condition9 && condition10 && condition11 && condition12 && condition13 && condition14 && condition15 && condition17 && condition18 && condition19 && condition20 && condition21 && condition22 && condition23 && condition24 && condition25 ? 1 : 0;
    });

    setConfigData(finalValues);
  }, [data, enabledAgencyId]);

  return (
    <React.Fragment>
      <Head title="Agencies"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockBetween>
                <BlockTitle page tag="h3">
                  Agencies Config
                </BlockTitle>
                {/* configuration Alert */}
                {configFailureAlert && (
                  <div className="alert-container ml-5" style={{ width: "904px" }}>
                    {/* <Alert className="alert-icon" color="danger">
                    <Icon name="alert-circle" />
                    Validation <strong>Default Value</strong> Alerted. Default configuration settings have been changed for {" "}
                    <a href="#" className="alert-link">
                      {enabledAgencyName}
                    </a>{" "} agency
                  </Alert> */}
                    <Alert className="alert-icon" style={{ backgroundColor: "#E85347", color: "#ffffff" }}>
                      <Icon name="alert-circle" />
                      Validation <strong>Default Value</strong> Alerted. Default configuration settings have been
                      changed for <u style={{ fontWeight: "bold" }}>{enabledAgencyName}</u>
                    </Alert>
                  </div>
                )}
                {configSuccessAlert && client_id !== 52 && (
                  <div className="alert-container ml-5" style={{ width: "904px" }}>
                    <UncontrolledAlert className="alert-icon" style={{ backgroundColor: "#1EE0AC", color: "#ffffff" }}>
                      <Icon name="check-circle" />
                      <strong>No Changes </strong>Detected. Configuration default values remain unchanged for{" "}
                      <u style={{ fontWeight: "bold" }}>{enabledAgencyName}</u>
                    </UncontrolledAlert>
                  </div>
                )}
              </BlockBetween>
            </BlockHeadContent>
          </BlockBetween>
          <BlockBetween>
            <BlockHeadContent>
              {client_id !== 52 && (
                <div className="checkbox-container">
                  <div className="checkbox">
                    <div className="select">
                      <h6>Status :</h6>
                    </div>
                    {/*  <input type="checkbox" checked={approvedChecked} onChange={handleApprovedChange} />
                    <label style={{ marginLeft: "3px" }}>Active</label>
                    <input
                      style={{ marginLeft: "11px" }}
                      type="checkbox"
                      checked={pendingChecked}
                      onChange={handlePendingChange}
                    />
                    <label style={{ marginLeft: "3px" }}>Inactive</label>
                    */}
                    <div className="d-flex justify-content-center">
                      <UncontrolledDropdown>
                        <Alert
                          className="alert-pro"
                          color="primary"
                          style={{ paddingTop: 8, paddingLeft: 15, paddingRight: 15, paddingBottom: 8 }}
                        >
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input
                              type="checkbox"
                              checked={approvedChecked}
                              onClick={() => handleApprovedChange()}
                              className="custom-control-input form-control"
                              id="expand-data1"
                            />
                            <label className="custom-control-label" htmlFor="expand-data1">
                              Active
                            </label>
                          </div>
                        </Alert>
                      </UncontrolledDropdown>
                      <UncontrolledDropdown className="pl-1">
                        <Alert
                          className="alert-pro"
                          color="primary"
                          style={{ paddingTop: 8, paddingLeft: 15, paddingRight: 15, paddingBottom: 8 }}
                        >
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input
                              type="checkbox"
                              checked={pendingChecked}
                              onClick={handlePendingChange}
                              className="custom-control-input form-control"
                              id="expand-data2"
                            />
                            <label className="custom-control-label" htmlFor="expand-data2">
                              Inactive
                            </label>
                          </div>
                        </Alert>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                  <div className="checkbox">
                    <div className="select">
                      <h6>Smart Agency :</h6>
                    </div>
                    {/* <input type="checkbox" checked={enabledChecked} onChange={handleApprovedChange2} />
                    <label style={{ marginLeft: "3px" }}>Enabled</label>
                    <input
                      style={{ marginLeft: "11px" }}
                      type="checkbox"
                      checked={readOnlyChecked}
                      onChange={handlePendingChange2}
                    />
                    <label style={{ marginLeft: "3px" }}>Disabled</label> */}
                    <div className="d-flex justify-content-center">
                      <UncontrolledDropdown>
                        <Alert
                          className="alert-pro"
                          color="primary"
                          style={{ paddingTop: 8, paddingLeft: 15, paddingRight: 15, paddingBottom: 8 }}
                        >
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input
                              type="checkbox"
                              checked={enabledChecked}
                              onClick={handleApprovedChange2}
                              className="custom-control-input form-control"
                              id="expand-data3"
                            />
                            <label className="custom-control-label" htmlFor="expand-data3">
                              Enabled
                            </label>
                          </div>
                        </Alert>
                      </UncontrolledDropdown>
                      <UncontrolledDropdown className="pl-1">
                        <Alert
                          className="alert-pro"
                          color="primary"
                          style={{ paddingTop: 8, paddingLeft: 15, paddingRight: 15, paddingBottom: 8 }}
                        >
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input
                              type="checkbox"
                              checked={readOnlyChecked}
                              onClick={handlePendingChange2}
                              className="custom-control-input form-control"
                              id="expand-data4"
                            />
                            <label className="custom-control-label" htmlFor="expand-data4">
                              Disabled
                            </label>
                          </div>
                        </Alert>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              )}
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand mr-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    updateSm(!sm);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="Search by Agency Name"
                          onChange={(e) => onFilterChange(e)}
                          value={onSearchText}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card>
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <table style={{ width: "100%", tableLayout: "auto", textAlign: "center" }} className="table">
                  <thead className="table-light">
                    <tr>
                      <th className="d-none d-md-table-cell" onClick={() => handleSort("clientname")}>
                        Agency Name {renderSortIcon2()}
                      </th>
                      <th className="d-none d-md-table-cell">Latitude {"&"} Longitude</th>
                      <th className="d-none d-sm-table-cell">Status</th>
                      <th className="d-none d-sm-table-cell">Smart Agency</th>
                      <th>Config</th>
                      <th className="d-none d-sm-table-cell" onClick={() => handleSort("createddate")}>
                        Created Date {renderSortIcon()}
                      </th>
                      <th className="d-none d-sm-table-cell">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length > 0 ? (
                      filteredItemsNew.map((item, idx) => {
                        return (
                          <tr key={idx} className="tb-tnx-item">
                            <td style={{ padding: "0.75rem 0.25rem" }}>
                              <strong>{item.clientname.length > 0 ? item.clientname : "NA"}</strong>
                            </td>
                            <td style={{ padding: "0.75rem 0.25rem" }}>
                              {item.clientlat}
                              <br />
                              {item.clientlng}
                            </td>
                            <td style={{ padding: "0.75rem 0.25rem" }}>
                              {item.status ? (
                                <Badge pill color="success">
                                  <strong>Active</strong>
                                </Badge>
                              ) : (
                                <Badge pill color="warning">
                                  inactive
                                </Badge>
                              )}
                            </td>
                            <td style={{ padding: "0.75rem 0.25rem" }}>
                              {item.smart_venues ? (
                                <Badge pill color="success">
                                  Enabled
                                </Badge>
                              ) : (
                                <Badge pill color="warning ">
                                  Disabled
                                </Badge>
                              )}
                            </td>

                            <td>
                              {currentConfigData[idx] === 1 ? (
                                <>
                                  <Button id="PopoverDismisable" className="btn-icon">
                                    <Icon
                                      style={{ color: "#1EE0AC", fontSize: "24px" }}
                                      name="check-fill-c"
                                      id="PopoverDismisable"
                                    />
                                  </Button>
                                  <UncontrolledPopover target="PopoverDismisable" trigger="hover">
                                    <PopoverHeader>Config Info</PopoverHeader>
                                    <PopoverBody>No changes in default configurations</PopoverBody>
                                  </UncontrolledPopover>
                                </>
                              ) : (
                                <>
                                  <Button id="PopoverDismisable" className="btn-icon">
                                    <Icon
                                      style={{ color: "#E85347", fontSize: "24px" }}
                                      name="cross-fill-c"
                                      id="PopoverDismisable"
                                    />
                                  </Button>
                                  <UncontrolledPopover target="PopoverDismisable" trigger="hover">
                                    <PopoverHeader>Config Info</PopoverHeader>
                                    <PopoverBody>Default configurations are changed</PopoverBody>
                                  </UncontrolledPopover>
                                </>
                              )}
                            </td>

                            <td style={{ padding: "0.75rem 0.25rem" }}>
                              <Moment format="MMMM Do YYYY, h:mm a">{item.createddate}</Moment>
                            </td>

                            <td style={{ padding: "0.75rem 0.25rem" }}>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  href="#more"
                                  onClick={(ev) => ev.preventDefault()}
                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          onEditClick(item.clientid);
                                        }}
                                      >
                                        <Icon name="edit-fill"></Icon>
                                        <span>Edit</span>
                                      </DropdownItem>
                                    </li>

                                    {/* <li>
                                          <DropdownItem
                                            tag="a"
                                            href="#remove"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="trash"></Icon>
                                            <span>Remove </span>
                                          </DropdownItem>
                                        </li> */}
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6">
                          <div className="text-center">
                            <span className="text-silent">
                              {isLoading ? <Spinner color="primary" /> : "No Agencies Available"}
                            </span>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <div className="card-inner">
                  {client_id === 52 ? (
                    <PaginationComponent itemPerPage={1} totalItems={1} paginate={paginate} currentPage={1} />
                  ) : (
                    data.length > 0 && (
                      <PaginationComponent
                        itemPerPage={itemPerPage}
                        totalItems={data.length}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    )
                  )}
                </div>

                {/* <div className="card-inner">
                  {data.length > 0 ? (
                    <PaginationComponent
                      itemPerPage={itemPerPage}
                      totalItems={data.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  ) : (
                    <div className="text-center">
                      <span className="text-silent">{isLoading ? <Spinner color="primary" /> : "No Data found"}</span>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          </Card>
        </Block>
        <Modal
          isOpen={view}
          toggle={() => onFormCancel()}
          style={{ zIndex: 1000 }}
          className="modal-dialog-centered"
          size="xl"
        >
          <ModalBody>
            <a href="#cancel" className="close">
              {" "}
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Edit</h5>
              <form onSubmit={handleSubmit(checkAuthentication)}>
                <PreviewCard>
                  <Nav tabs>
                    {[
                      { title: "Basic", tab: "1" },
                      { title: "Ticket Payment", tab: "2" },
                      { title: "Device Setup", tab: "3" },
                      { title: "Branding", tab: "4" },
                      // { title: "Rssi", tab: "5" },
                      { title: "Config", tab: "6" },
                      process.env.REACT_APP_PANEL_TEXT === "ZIG" && { title: "Fare Capping", tab: "7" },
                      process.env.REACT_APP_PANEL_TEXT === "ZIG" && { title: "Beverage", tab: "8" },
                    ].map((item, idx) => (
                      <NavItem key={idx} style={{ cursor: "pointer" }}>
                        <NavLink
                          className={activeTab === item.tab ? "active" : ""}
                          onClick={() => setActiveTab(item.tab)}
                        >
                          {item.title}
                          {tabWiseErrors[idx] === 1 ? <font color="red">*</font> : null}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>

                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="clientname" className="form-label">
                              Client Name
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="clientname"
                                name="clientname"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.clientname}
                              />
                              {errors.clientname && <span className="invalid">This field is required</span>}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="clientuuid" className="form-label">
                              Client UUID
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="clientuuid"
                                name="clientuuid"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.clientuuid}
                              />
                              {errors.clientuuid && <span className="invalid">This field is required</span>}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="clientlat" className="form-label">
                              Location
                            </Label>
                            <div className="form-control-wrap">
                              <div className="input-group">
                                <input
                                  ref={register({ required: true })}
                                  type="text"
                                  className="form-control"
                                  name="clientlat"
                                  id="clientlat"
                                  onChange={(e) => setFormData((prev) => ({ ...prev, clientlat: +e.target.value }))}
                                  placeholder="Latitude"
                                  value={formData?.clientlat}
                                />
                                <input
                                  type="text"
                                  ref={register({ required: true })}
                                  className="form-control"
                                  name="clientlng"
                                  id="clientlng"
                                  onChange={(e) => setFormData((prev) => ({ ...prev, clientlng: +e.target.value }))}
                                  placeholder="Longitude"
                                  value={formData?.clientlng}
                                />
                                {(errors.clientlat || errors.clientlng) && (
                                  <span className="invalid">This field is required</span>
                                )}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="radius" className="form-label">
                              Radius
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true, min: 0 })}
                                className="form-control"
                                type="number"
                                id="radius"
                                name="radius"
                                onChange={(e) => setFormData((prev) => ({ ...prev, radius: +e.target.value }))}
                                value={formData?.radius}
                              />
                              {errors.radius && <span className="invalid">This field is required</span>}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="serviceinterval" className="form-label">
                              Service Interval
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true, min: 0 })}
                                className="form-control"
                                defaultValue={1}
                                type="number"
                                onChange={(e) => setFormData((prev) => ({ ...prev, serviceinterval: +e.target.value }))}
                                value={formData?.serviceinterval}
                                id="serviceinterval"
                                name="serviceinterval"
                                readOnly
                              />
                              {errors.Serviceinterval && <span className="invalid">This field is required</span>}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="status">
                              Status
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.status === 0}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, status: 1 }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.status === 1}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, status: 0 }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        {[
                          { title: "Age Verification", name: "ageverification" },
                          { title: "Enable Ticket", name: "enableticket" },
                          { title: "Enable Trip Planner", name: "enabletripplanner" },
                          { title: "Free Ticket", name: "freeticket" },
                        ].map((item) => (
                          <Col sm="3" key={item.name}>
                            <FormGroup>
                              <Label htmlFor={item.name} className="form-label">
                                {item.title}
                              </Label>
                              <div className="form-control-wrap">
                                <div className="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input form-control"
                                    id={item.name}
                                    name={item.name}
                                    onChange={(e) =>
                                      setFormData((prev) => ({ ...prev, [item.name]: !formData[item.name] }))
                                    }
                                    checked={formData[item.name]}
                                    placeholder=""
                                  />
                                  <label className="custom-control-label" htmlFor={item.name}></label>
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                        ))}
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row className="gy-4">
                        <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="clientpayment" className="form-label">
                              Client Payment
                            </Label>
                            <div className="form-control-wrap">
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input form-control"
                                  id="clientpayment"
                                  name="clientpayment"
                                  placeholder=""
                                  onChange={(e) =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      clientpayment: !formData?.clientpayment,
                                    }))
                                  }
                                  checked={formData?.clientpayment}
                                />
                                <label className="custom-control-label" htmlFor="clientpayment"></label>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="activateStatus">
                              Activate Status
                            </label>
                            <div className="form-control-wrap">
                              <div className="custom-control custom-switch">
                                <input
                                  ref={register()}
                                  type="checkbox"
                                  className="custom-control-input form-control"
                                  id="activateStatus"
                                  name="activateStatus"
                                  checked={formData?.activateStatus}
                                  onChange={(e) =>
                                    setFormData((prev) => ({ ...prev, activateStatus: !formData?.activateStatus }))
                                  }
                                  placeholder=""
                                />
                                <label className="custom-control-label" htmlFor="activateStatus">
                                  {formData?.activateStatus ? "Active" : "Inactive"}
                                </label>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="freeticket" className="form-label">
                              Free Ticket
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true, min: 0 })}
                                className="form-control"
                                type="number"
                                id="freeticket"
                                name="freeticket"
                                onChange={(e) => setFormData((prev) => ({ ...prev, freeticket: +e.target.value }))}
                                value={formData?.freeticket}
                              />
                              {errors.freeticket && <span className="invalid">This field is required</span>}
                            </div>
                          </FormGroup>
                        </Col> */}
                        <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="scaninterval" className="form-label">
                              Scan Interval
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true, min: 0 })}
                                className="form-control"
                                type="number"
                                id="scaninterval"
                                name="scaninterval"
                                onChange={(e) => setFormData((prev) => ({ ...prev, scaninterval: +e.target.value }))}
                                value={formData?.scaninterval}
                                readOnly
                              />
                              {errors.scaninterval && <span className="invalid">This field is required</span>}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="miscfee" className="form-label">
                              Misc Fee
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="miscfee"
                                name="miscfee"
                                onChange={(e) => setFormData((prev) => ({ ...prev, miscfee: +e.target.value }))}
                                value={formData?.miscfee}
                              />
                              {errors.miscfee && <span className="invalid">This field is required</span>}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="Message" className="form-label">
                              Activate Status Message
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="Message"
                                name="Message"
                                value={formData?.Message ?? "NA"}
                                onChange={(e) => onInputChange(e)}
                              />
                              {errors.Message && <span className="invalid">This field is required</span>}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row className="gy-4">
                        <Col sm="6">
                          <FormGroup>
                            <label className="form-label">A Validation Mode</label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.validationmode !== 0}
                                  onClick={() => setFormData((prev) => ({ ...prev, validationmode: 0 }))}
                                >
                                  Dual Connection
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.validationmode !== 1}
                                  onClick={() => setFormData((prev) => ({ ...prev, validationmode: 1 }))}
                                >
                                  MQTT Mode
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.validationmode !== 2}
                                  onClick={() => setFormData((prev) => ({ ...prev, validationmode: 2 }))}
                                >
                                  BLE Mode
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.validationmode !== 3}
                                  onClick={() => setFormData((prev) => ({ ...prev, validationmode: 3 }))}
                                >
                                  NC
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <label className="form-label">B Validation Mode</label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.validationmode_b !== 0}
                                  onClick={() => setFormData((prev) => ({ ...prev, validationmode_b: 0 }))}
                                >
                                  Dual Connection
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.validationmode_b !== 1}
                                  onClick={() => setFormData((prev) => ({ ...prev, validationmode_b: 1 }))}
                                >
                                  MQTT Mode
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.validationmode_b !== 2}
                                  onClick={() => setFormData((prev) => ({ ...prev, validationmode_b: 2 }))}
                                >
                                  BLE Mode
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.validationmode_b !== 3}
                                  onClick={() => setFormData((prev) => ({ ...prev, validationmode_b: 3 }))}
                                >
                                  NC
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <ul className="custom-control-group custom-control-vertical w-100">
                            {formData?.Macaddresslist?.length > 0
                              ? formData?.Macaddresslist?.map((item, idx) => (
                                  <li key={idx}>
                                    <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                      <input
                                        type="radio"
                                        className="custom-control-input"
                                        checked
                                        id={item.Bus_serialno}
                                      />
                                      <label className="custom-control-label" htmlFor="paymentCheck1">
                                        <span>{item.Bus_serialno} </span>
                                      </label>
                                    </div>
                                  </li>
                                ))
                              : null}
                          </ul>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row className="gy-4">
                        {[
                          { title: "Client Image URL", name: "clientimage" },
                          { title: "Client Description", name: "clientdesc" },
                          { title: "Client Disclaimer", name: "clientdisclaimer" },
                          { title: "Client Misc Image URL", name: "clientmiscimage" },
                          // { title: "Beverage Instruction Text", name: "beverageinstructiontext" },
                          // { title: "Beverage Title", name: "beveragetitle" },
                        ].map((item, idx) => (
                          <Col key={idx} sm="6">
                            <FormGroup>
                              <Label htmlFor={item.name} className="form-label">
                                {item.title}
                              </Label>
                              <div className="form-control-wrap">
                                <input
                                  ref={register({ required: true })}
                                  className="form-control"
                                  type="text"
                                  id={item.name}
                                  name={item.name}
                                  onChange={(e) => setFormData((prev) => ({ ...prev, [item.name]: e.target.value }))}
                                  value={formData[item.name] ?? "NA"}
                                />
                                {errors[item.name] && <span className="invalid">This field is required</span>}
                              </div>
                            </FormGroup>
                          </Col>
                        ))}
                        {[
                          { title: "Verification Title", name: "verificationtitle" },
                          { title: "Verification Sub Title", name: "verificationsubtitle" },
                          { title: "Verification Description", name: "verificationdesc" },
                        ].map((item) => (
                          <Col sm="3">
                            <FormGroup>
                              <Label htmlFor={item.name} className="form-label">
                                {item.title}
                              </Label>
                              <div className="form-control-wrap">
                                <input
                                  ref={register({ required: true })}
                                  className="form-control"
                                  type="text"
                                  id={item.name}
                                  name={item.name}
                                  onChange={(e) => setFormData((prev) => ({ ...prev, [item.name]: e.target.value }))}
                                  value={formData[item.name] ?? "NA"}
                                />
                                {errors[item.name] && <span className="invalid">This field is required</span>}
                              </div>
                            </FormGroup>
                          </Col>
                        ))}
                        <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="verificationstatus" className="form-label">
                              Verification Status
                            </Label>
                            <div className="form-control-wrap">
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input form-control"
                                  id="verificationstatus"
                                  name="verificationstatus"
                                  onChange={() =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      verificationstatus: !formData?.verificationstatus,
                                    }))
                                  }
                                  checked={formData?.verificationstatus ?? false}
                                  placeholder=""
                                />
                                <label className="custom-control-label" htmlFor="verificationStatus"></label>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                        {[
                          { title: "Primary Color Code", name: "primarycolorcode" },
                          { title: "Secondary Color Code", name: "secondarycolorcode" },
                          { title: "Client Primary Color Code", name: "clientprimarycolorcode" },
                          { title: "Client Secondary Color Code", name: "clientsecondaryarycolorcode" },
                        ].map((item, idx) => (
                          <Col sm="3" key={item.name}>
                            <FormGroup>
                              <Label htmlFor={item.name} className="form-label">
                                {item.title}
                              </Label>
                              <div className="form-control-wrap" style={{ padding: "0.25rem" }}>
                                <SketchPicker
                                  color={formData[item.name] ?? "#333333"}
                                  onChange={(color, e) => {
                                    setFormData((prevState) => ({ ...prevState, [item.name]: color.hex }));
                                  }}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        ))}
                        {[
                          { title: "Step 1 Text", name: "step1text" },
                          { title: "Step 2 Text", name: "step2text" },
                          { title: "Pay Button Text", name: "paybuttontext", defaultText: "Get Ticket" },
                        ].map((item) => (
                          <Col sm="4">
                            <FormGroup>
                              <Label htmlFor={item.name} className="form-label">
                                {item.title}
                              </Label>
                              <div className="form-control-wrap">
                                <input
                                  ref={register({ required: true })}
                                  className="form-control"
                                  type="text"
                                  id={item.name}
                                  onChange={(e) => onInputChange(e)}
                                  value={formData[item.name] ?? "NA"}
                                  name={item.name}
                                />
                                {errors[item.name] && <span className="invalid">This field is required</span>}
                              </div>
                            </FormGroup>
                          </Col>
                        ))}
                        {[
                          { title: "Client Sub Description", name: "clientsubdesc" },
                          { title: "Order Confirmed Title", name: "order_confirmed_title" },
                          { title: "Order Confirmed Subtitle", name: "order_confirmed_subtitle" },
                        ].map((item) => (
                          <Col sm="4">
                            <FormGroup>
                              <Label htmlFor={item.name} className="form-label">
                                {item.title}
                              </Label>
                              <div className="form-control-wrap">
                                <input
                                  ref={register({ required: true })}
                                  className="form-control"
                                  type="text"
                                  id={item.name}
                                  name={item.name}
                                  onChange={(e) => onInputChange(e)}
                                  value={formData[item.name] ?? "NA"}
                                />
                                {errors[item.name] && <span className="invalid">This field is required</span>}
                              </div>
                            </FormGroup>
                          </Col>
                        ))}
                        {[
                          {
                            title: "Payment Info Text",
                            name: "payment_info_text",
                            defaultText: "You will be charged from your wallet",
                          },
                          { title: "FarepageTitle", name: "farepagetitle" },
                          { title: "Fare Message", name: "faremessage" },
                        ].map((item, idx) => (
                          <Col key={idx} sm="6">
                            <FormGroup>
                              <Label htmlFor={item.name} className="form-label">
                                {item.title}
                              </Label>
                              <div className="form-control-wrap">
                                <input
                                  ref={register({ required: true })}
                                  className="form-control"
                                  type="text"
                                  id={item.name}
                                  onChange={(e) => onInputChange(e)}
                                  value={formData[item.name] ?? "NA"}
                                  name={item.name}
                                />
                                {errors[item.name] && <span className="invalid">This field is required</span>}
                              </div>
                            </FormGroup>
                          </Col>
                        ))}
                      </Row>
                    </TabPane>
                    <TabPane tabId="5">
                      {/* <Row>
                        <Col sm="6">
                          <div className="form-group">
                            <label className="form-label">Android A Range</label>
                            <div className="form-control-wrap">
                              <Nouislider
                                className="form-range-slider"
                                accessibility
                                onChange={(props) => {
                                  let num = `${getRssiValueFromFt(props[0])}`;
                                  setFormData((prev) => ({ ...prev, rssvalue: num }));
                                }}
                                tooltips={true}
                                padding={1}
                                connect={[true, false]}
                                start={[getFtFromRssiValue(+formData?.rssvalue)]}
                                step={0.01}
                                range={{
                                  min: 0,
                                  max: 11,
                                }}
                              ></Nouislider>
                              <div className=" d-flex justify-content-between">
                                <span>
                                  {formData?.rssvalue ? getFtFromRssiValue(+formData?.rssvalue) + " ( ft )" : "Null"}
                                </span>
                                <div className="d-flex align-items-center">
                                  <label className="m-2">Rssi</label>
                                  <input
                                    type="number"
                                    name="rssvalue"
                                    ref={register({ required: true })}
                                    value={+formData?.rssvalue === 0 ? "" : +formData?.rssvalue}
                                    className="form-control"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, rssvalue: e.target.value }))}
                                  />
                                  {errors.rssvalue ? <span className="invalid">Invalid Input </span> : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm="6">
                          <div className="form-group">
                            <label className="form-label">Android B Range</label>
                            <div className="form-control-wrap">
                              <Nouislider
                                className="form-range-slider"
                                accessibility
                                onChange={(props) => {
                                  let num = `${getRssiValueFromFt(props[0])}`;
                                  setFormData((prev) => ({ ...prev, bibobrssi: num }));
                                }}
                                tooltips={true}
                                padding={1}
                                connect={[true, false]}
                                start={[getFtFromRssiValue(+formData?.bibobrssi)]}
                                step={0.01}
                                range={{
                                  min: 0,
                                  max: 11,
                                }}
                              ></Nouislider>
                              <div className=" d-flex justify-content-between">
                                <span>
                                  {formData?.bibobrssi ? getFtFromRssiValue(+formData?.bibobrssi) + " ( ft )" : "Null"}
                                </span>
                                <div className="d-flex align-items-center">
                                  <label className="m-2">Rssi</label>
                                  <input
                                    type="number"
                                    name="bibobrssi"
                                    ref={register({ required: true })}
                                    value={+formData?.bibobrssi === 0 ? "" : +formData?.bibobrssi}
                                    className="form-control"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, bibobrssi: e.target.value }))}
                                  />
                                  {errors.bibobrssi ? <span className="invalid">Invalid Input </span> : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm="6">
                          <div className="form-group">
                            <label className="form-label">iOS A Range</label>
                            <div className="form-control-wrap">
                              <Nouislider
                                className="form-range-slider"
                                accessibility
                                onChange={(props) => {
                                  let num = `${getRssiValueFromFt(props[0])}`;
                                  setFormData((prev) => ({ ...prev, rssi_ios: num }));
                                }}
                                tooltips={true}
                                padding={1}
                                connect={[true, false]}
                                start={[getFtFromRssiValue(+formData?.rssi_ios)]}
                                step={0.01}
                                range={{
                                  min: 0,
                                  max: 11,
                                }}
                              ></Nouislider>
                              <div className=" d-flex justify-content-between">
                                <span>
                                  {formData?.rssi_ios ? getFtFromRssiValue(+formData?.rssi_ios) + " ( ft )" : "Null"}
                                </span>
                                <div className="d-flex align-items-center">
                                  <label className="m-2">Rssi</label>
                                  <input
                                    type="number"
                                    name="rssi_ios"
                                    ref={register({ required: true })}
                                    value={+formData?.rssi_ios === 0 ? "" : +formData?.rssi_ios}
                                    className="form-control"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, rssi_ios: e.target.value }))}
                                  />
                                  {errors.rssi_ios ? <span className="invalid">Invalid Input </span> : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm="6">
                          <div className="form-group">
                            <label className="form-label">iOS B Range</label>
                            <div className="form-control-wrap">
                              <Nouislider
                                className="form-range-slider"
                                accessibility
                                onChange={(props) => {
                                  let num = `${getRssiValueFromFt(props[0])}`;
                                  setFormData((prev) => ({ ...prev, bibobrssi_ios: num }));
                                }}
                                tooltips={true}
                                padding={1}
                                connect={[true, false]}
                                start={[getFtFromRssiValue(+formData?.bibobrssi_ios)]}
                                step={0.01}
                                range={{
                                  min: 0,
                                  max: 11,
                                }}
                              ></Nouislider>
                              <div className=" d-flex justify-content-between">
                                <span>
                                  {formData?.bibobrssi_ios
                                    ? getFtFromRssiValue(+formData?.bibobrssi_ios) + " ( ft )"
                                    : "Null"}
                                </span>
                                <div className="d-flex align-items-center">
                                  <label className="m-2">Rssi</label>
                                  <input
                                    type="number"
                                    name="bibobrssi_ios"
                                    ref={register({ required: true })}
                                    value={+formData?.bibobrssi_ios === 0 ? "" : +formData?.bibobrssi_ios}
                                    className="form-control"
                                    onChange={(e) =>
                                      setFormData((prev) => ({ ...prev, bibobrssi_ios: e.target.value }))
                                    }
                                  />
                                  {errors.bibobrssi_ios ? <span className="invalid">Invalid Input </span> : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row> */}
                    </TabPane>
                    <TabPane tabId="6">
                      <br></br>
                      <Row>
                        <Col sm="3">
                          <Label htmlFor="service_enable" className="form-label">
                            Service Enable
                          </Label>
                          <div className="form-control-wrap">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id="service_enable"
                                name="service_enable"
                                placeholder=""
                                onChange={(e) =>
                                  setFormData((prev) => ({
                                    ...prev,
                                    service_enable: !formData?.service_enable,
                                  }))
                                }
                                checked={formData?.service_enable}
                              />
                              <label className="custom-control-label" htmlFor="service_enable"></label>
                            </div>
                          </div>
                        </Col>
                        <Col sm="4">
                          <FormGroup>
                            <label className="form-label" htmlFor="ibeacon_status">
                              IOS iBeacon Status
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.ibeacon_status === 2 || formData?.ibeacon_status === 3}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, ibeacon_status: 1 }));
                                  }}
                                >
                                  Immediate
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.ibeacon_status === 1 || formData?.ibeacon_status === 3}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, ibeacon_status: 2 }));
                                  }}
                                >
                                  Near
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.ibeacon_status === 1 || formData?.ibeacon_status === 2}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, ibeacon_status: 3 }));
                                  }}
                                >
                                  Far
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup>
                            <label className="form-label" htmlFor="ibeaconandroidstatus">
                              Android iBeacon Status
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={
                                    formData?.ibeaconandroidstatus === "2" || formData?.ibeaconandroidstatus === "3"
                                  }
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, ibeaconandroidstatus: "1" }));
                                  }}
                                >
                                  Immediate
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={
                                    formData?.ibeaconandroidstatus === "1" || formData?.ibeaconandroidstatus === "3"
                                  }
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, ibeaconandroidstatus: "2" }));
                                  }}
                                >
                                  Near
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={
                                    formData?.ibeaconandroidstatus === "1" || formData?.ibeaconandroidstatus === "2"
                                  }
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, ibeaconandroidstatus: "3" }));
                                  }}
                                >
                                  Far
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br></br>
                      <Row className="gy-4">
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="smart_venues">
                              Smart Agency
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.smart_venues === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, smart_venues: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.smart_venues === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, smart_venues: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="frequency_interval_line">
                              Freq Interval Line
                            </label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="frequency_interval_line"
                                name="frequency_interval_line"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.frequency_interval_line}
                                readOnly
                              />
                              {errors.frequency_interval_line && (
                                <span className="invalid">This field is required</span>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="frequency_interval_realtime_bus">
                              Freq Interval Realtime Bus
                            </label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="frequency_interval_realtime_bus"
                                name="frequency_interval_realtime_bus"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.frequency_interval_realtime_bus}
                                readOnly
                              />
                              {errors.frequency_interval_realtime_bus && (
                                <span className="invalid">This field is required</span>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="averagerssivalue">
                              Avg RSSI Value
                            </label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="averagerssivalue"
                                name="averagerssivalue"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.averagerssivalue}
                                readOnly
                              />
                              {errors.averagerssivalue && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <br></br>
                      <Row className="gy-4">
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">TX Power</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="tx_power"
                                name="tx_power"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.tx_power}
                                readOnly
                              />
                              {errors.tx_power && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Distance</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="distance"
                                name="distance"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.distance}
                                readOnly
                              />
                              {errors.distance && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Measure Power</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="measurepower"
                                name="measurepower"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.measurepower}
                                readOnly
                              />
                              {errors.measurepower && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Power Value</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="powervalue"
                                name="powervalue"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.powervalue}
                                readOnly
                              />
                              {errors.powervalue && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <br></br>
                      <Row className="gy-4">
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Distance Times</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="distancetimes"
                                name="distancetimes"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.distancetimes}
                                readOnly
                              />
                              {errors.distancetimes && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="acknowledgement">
                              Acknowledgement
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.acknowledgement === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, acknowledgement: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.acknowledgement === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, acknowledgement: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="scanmode">
                              Scan Mode
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.scanmode === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, scanmode: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.scanmode === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, scanmode: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="analyticlal">
                              Analytical Data
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.analyticlal === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, analyticlal: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.analyticlal === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, analyticlal: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br></br>
                      <Row className="gy-4">
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Foreground Immediate range/mts</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="ble_rssi_5a"
                                name="ble_rssi_5a"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.ble_rssi_5a}
                                readOnly
                              />
                              {errors.ble_rssi_5a && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">
                              Foreground Near <br />
                              range/mts
                            </label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="ble_rssi_5b"
                                name="ble_rssi_5b"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.ble_rssi_5b}
                                readOnly
                              />
                              {errors.ble_rssi_5b && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Background Immediate range/mts</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="ble_rssi_4"
                                name="ble_rssi_4"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.ble_rssi_4}
                                readOnly
                              />
                              {errors.ble_rssi_4 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">
                              Background Near <br />
                              range/mts
                            </label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="ble_rssi_5c"
                                name="ble_rssi_5c"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.ble_rssi_5c}
                                readOnly
                              />
                              {errors.ble_rssi_5c && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <br></br>
                      <Row className="gy-4">
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="forceupdate">
                              Force Update
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.forceupdate === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, forceupdate: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.forceupdate === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, forceupdate: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Force Update Title</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="forceupdatetitle"
                                name="forceupdatetitle"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.forceupdatetitle}
                              />
                              {errors.forceupdatetitle && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Force Update Description</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="forceupdatedescription"
                                name="forceupdatedescription"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.forceupdatedescription}
                              />
                              {errors.forceupdatedescription && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Android Version Number</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="androidversionnumber"
                                name="androidversionnumber"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.androidversionnumber}
                              />
                              {errors.androidversionnumber && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">IOS Version Number</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="iosversionnumber"
                                name="iosversionnumber"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.iosversionnumber}
                              />
                              {errors.iosversionnumber && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Menu Item</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="menuitem"
                                name="menuitem"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.menuitem}
                              />
                              {errors.menuitem && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Here Api Key</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="here_api_key"
                                name="here_api_key"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.here_api_key}
                              />
                              {errors.here_api_key && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">QOS</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="qos"
                                name="qos"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.qos}
                              />
                              {errors.qos && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="sos">
                              SOS
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.sos === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, sos: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.sos === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, sos: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="stop">
                              Stop
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.stop === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, stop: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.stop === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, stop: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="screen_wake_status">
                              Screen Wake Status
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.screen_wake_status === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, screen_wake_status: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.screen_wake_status === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, screen_wake_status: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="alarm_service">
                              Alarm Service
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.alarm_service === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, alarm_service: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.alarm_service === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, alarm_service: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br></br>
                      <Row className="gy-4">
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="auto_validation_feature">
                              Auto Validation
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.auto_validation_feature === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, auto_validation_feature: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.auto_validation_feature === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, auto_validation_feature: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="visitoroccupency">
                              Visitor Occupancy
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.visitoroccupency === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, visitoroccupency: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.visitoroccupency === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, visitoroccupency: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="beveragestatus">
                              Beverage Status
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.beveragestatus === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, beveragestatus: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.beveragestatus === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, beveragestatus: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="wallet">
                              Wallet
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.wallet === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, wallet: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.wallet === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, wallet: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br></br>
                      <Row className="gy-4">
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="tripplannerstatus">
                              Trip Planner Status
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.tripplannerstatus === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, tripplannerstatus: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.tripplannerstatus === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, tripplannerstatus: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="rewardsstatus">
                              Rewards Status
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.rewardsstatus === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, rewardsstatus: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.rewardsstatus === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, rewardsstatus: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="tripplanner_flag">
                              Trip Planner Flag
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.tripplanner_flag === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, tripplanner_flag: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.tripplanner_flag === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, tripplanner_flag: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="anaytical_flag">
                              Anaytical Flag
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.anaytical_flag === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, anaytical_flag: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.anaytical_flag === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, anaytical_flag: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="beverage_out_data_flag">
                              Beverage OutData Flag
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.beverage_out_data_flag === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, beverage_out_data_flag: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.beverage_out_data_flag === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, beverage_out_data_flag: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="sendoutdata">
                              Send Out Data
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.sendoutdata === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, sendoutdata: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.sendoutdata === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, sendoutdata: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="walletpayment">
                              Wallet Payment
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.walletpayment === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, walletpayment: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.walletpayment === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, walletpayment: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="showinui">
                              Show in UI
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.showinui === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, showinui: true }));
                                    setshowInUI(true);
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.showinui === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, showinui: false }));
                                    setshowInUI(false);
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="paymenttype" className="form-label">
                              Payment Type
                            </Label>
                            <div className="form-control-wrap">
                              <div className="form-control-select">
                                <Input
                                  type="select"
                                  name="paymenttype"
                                  id="paymenttype"
                                  onChange={(e) => onInputChange(e)}
                                  value={formData?.paymenttype}
                                >
                                  <option value="default_option">Default Option</option>
                                  <option value="1">Razorpay</option>
                                  <option value="2">Stripe</option>
                                  <option value="3">Cardknox</option>
                                </Input>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Cardnox Live Key</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="cardnox_live_key"
                                name="cardnox_live_key"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.cardnox_live_key}
                              />
                              {errors.cardnox_live_key && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Cardknox Sandbox Key</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="cardnox_sandbox_key"
                                name="cardnox_sandbox_key"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.cardnox_sandbox_key}
                              />
                              {errors.cardnox_sandbox_key && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Cardnox Reference No</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="cardnox_reference_no"
                                name="cardnox_reference_no"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.cardnox_reference_no}
                              />
                              {errors.cardnox_reference_no && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Process Noise</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="process_noise"
                                name="process_noise"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.process_noise}
                                readOnly
                              />
                              {errors.process_noise && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Measurement Noise</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="measurement_noise"
                                name="measurement_noise"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.measurement_noise}
                                readOnly
                              />
                              {errors.measurement_noise && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Formula Status</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="formula_status"
                                name="formula_status"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.formula_status}
                                readOnly
                              />
                              {errors.formula_status && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Threshold</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="threshold"
                                name="threshold"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.threshold}
                                readOnly
                              />
                              {errors.threshold && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Speech to Text</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="speech_to_text"
                                name="speech_to_text"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.speech_to_text}
                              />
                              {errors.speech_to_text && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Wallet Minimum Amount</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="minamount_wallet"
                                name="minamount_wallet"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.minamount_wallet}
                              />
                              {errors.minamount_wallet && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label">Wallet Maximum Amount</label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="maxamount_wallet"
                                name="maxamount_wallet"
                                onChange={(e) => onInputChange(e)}
                                value={formData?.maxamount_wallet}
                              />
                              {errors.maxamount_wallet && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="card_payment">
                              Card Payment
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.card_payment === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, card_payment: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.card_payment === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, card_payment: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="ai_feature">
                              AI Feature
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.ai_feature === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, ai_feature: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.ai_feature === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, ai_feature: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <hr className="preview-hr"></hr>
                      <BlockTitle tag="h5">Suggested Route</BlockTitle>
                      <Row className="gy-4">
                        <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="uber_status" className="form-label">
                              Uber Status
                            </Label>
                            <div className="form-control-wrap">
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input form-control"
                                  id="uber_status"
                                  name="uber_status"
                                  placeholder=""
                                  onChange={(e) =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      uber_status: !formData?.uber_status,
                                    }))
                                  }
                                  checked={formData?.uber_status}
                                />
                                <label className="custom-control-label" htmlFor="uber_status">
                                  {formData?.uber_status ? "True" : "False"}
                                </label>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="lyft_status">
                              Lyft Status
                            </label>
                            <div className="form-control-wrap">
                              <div className="custom-control custom-switch">
                                <input
                                  ref={register()}
                                  type="checkbox"
                                  className="custom-control-input form-control"
                                  id="lyft_status"
                                  name="lyft_status"
                                  checked={formData?.lyft_status}
                                  onChange={(e) =>
                                    setFormData((prev) => ({ ...prev, lyft_status: !formData?.lyft_status }))
                                  }
                                  placeholder=""
                                />
                                <label className="custom-control-label" htmlFor="lyft_status">
                                  {formData?.lyft_status ? "True" : "False"}
                                </label>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="louvelo_status">
                              Louvelo Status
                            </label>
                            <div className="form-control-wrap">
                              <div className="custom-control custom-switch">
                                <input
                                  ref={register()}
                                  type="checkbox"
                                  className="custom-control-input form-control"
                                  id="louvelo_status"
                                  name="louvelo_status"
                                  checked={formData?.louvelo_status}
                                  onChange={(e) =>
                                    setFormData((prev) => ({ ...prev, louvelo_status: !formData?.louvelo_status }))
                                  }
                                  placeholder=""
                                />
                                <label className="custom-control-label" htmlFor="louvelo_status">
                                  {formData?.louvelo_status ? "True" : "False"}
                                </label>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="enable_payment">
                              Enable Payment
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.enable_payment === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, enable_payment: true }));
                                  }}
                                >
                                  Enable
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.enable_payment === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, enable_payment: false }));
                                  }}
                                >
                                  Disable
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="microtransit_full_trip">
                              Microtransit Full Trip
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.microtransit_full_trip === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, microtransit_full_trip: true }));
                                  }}
                                >
                                  Show
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.microtransit_full_trip === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, microtransit_full_trip: false }));
                                  }}
                                >
                                  Hide
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label className="form-label" htmlFor="microtransit_first_mile">
                              Microtransit First Mile
                            </label>
                            <div className="form-control-wrap">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.microtransit_first_mile === false}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, microtransit_first_mile: true }));
                                  }}
                                >
                                  Show
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  outline={formData?.microtransit_first_mile === true}
                                  onClick={(e) => {
                                    setFormData((prev) => ({ ...prev, microtransit_first_mile: false }));
                                  }}
                                >
                                  Hide
                                </Button>
                              </ButtonGroup>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <hr className="preview-hr"></hr>
                      <BlockTitle tag="h5">Permission</BlockTitle>
                      <Row className="gy-4">
                        <Col sm="3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="force_permission">
                              Force Permission
                            </label>
                            <div className="form-control-wrap">
                              <div className="custom-control custom-switch">
                                <input
                                  ref={register()}
                                  type="checkbox"
                                  className="custom-control-input form-control"
                                  id="force_permission"
                                  name="force_permission"
                                  checked={formData?.force_permission}
                                  onChange={(e) =>
                                    setFormData((prev) => ({ ...prev, force_permission: !formData?.force_permission }))
                                  }
                                  placeholder=""
                                />
                                <label className="custom-control-label" htmlFor="force_permission">
                                  {formData?.force_permission ? "True" : "False"}
                                </label>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="permission_title" className="form-label">
                              Permission Title
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="permission_title"
                                name="permission_title"
                                value={formData?.permission_title ?? "NA"}
                                onChange={(e) => onInputChange(e)}
                              />
                              {errors.permission_title && <span className="invalid">This field is required</span>}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="permission_message" className="form-label">
                              Permission Message
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="permission_message"
                                name="permission_message"
                                value={formData?.permission_message ?? "NA"}
                                onChange={(e) => onInputChange(e)}
                              />
                              {errors.permission_message && <span className="invalid">This field is required</span>}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup>
                            <Label htmlFor="permission_description" className="form-label">
                              Permission Description
                            </Label>
                            <div className="form-control-wrap">
                              <textarea
                                ref={register({ required: true })}
                                className="form-control"
                                type="text"
                                id="permission_description"
                                name="permission_description"
                                value={formData?.permission_description ?? "NA"}
                                onChange={(e) => onInputChange(e)}
                              />
                              {errors.permission_description && <span className="invalid">This field is required</span>}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </TabPane>

                    {process.env.REACT_APP_PANEL_TEXT === "ZIG" && (
                      <TabPane tabId="7">
                        <br></br>
                        <Row className="gy-4">
                          <Col sm="3">
                            <FormGroup>
                              <Label htmlFor="fare_type" className="form-label">
                                Fare Type
                              </Label>
                              <div className="form-control-wrap">
                                <div className="form-control-select">
                                  <Input
                                    type="select"
                                    name="fare_type"
                                    id="fare_type"
                                    onChange={(e) => onInputChange(e)}
                                    value={formData?.fare_type}
                                  >
                                    <option value="Single Ride Pass">Single Ride Pass</option>
                                    <option value="Two-Week Pass">Two-Week Pass</option>
                                  </Input>
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col sm="3">
                            <FormGroup>
                              <Label htmlFor="cap_period" className="form-label">
                                Cap Period
                              </Label>
                              <div className="form-control-wrap">
                                <div className="form-control-select">
                                  <Input
                                    type="select"
                                    name="cap_period"
                                    id="cap_period"
                                    onChange={(e) => onInputChange(e)}
                                    value={formData?.cap_period}
                                  >
                                    <option value="Monthly">Monthly</option>
                                    <option value="Biweekly">Biweekly</option>
                                  </Input>
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col sm="3">
                            <div className="form-group">
                              <label className="form-label" htmlFor="enable">
                                Fare Capping
                              </label>
                              <div className="form-control-wrap">
                                <div className="custom-control custom-switch">
                                  <input
                                    ref={register()}
                                    type="checkbox"
                                    className="custom-control-input form-control"
                                    id="enable"
                                    name="enable"
                                    checked={formData?.enable}
                                    onChange={(e) =>
                                      setFormData((prev) => ({
                                        ...prev,
                                        enable: !formData?.enable,
                                      }))
                                    }
                                    placeholder=""
                                  />
                                  <label className="custom-control-label" htmlFor="enable">
                                    {formData?.enable ? "True" : "False"}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3">
                          <b>Note:</b>{" "}
                          <span className="text-primary">Fare capping is applied over a fixed calendar period</span>{" "}
                        </p>
                      </TabPane>
                    )}
                    {process.env.REACT_APP_PANEL_TEXT === "ZIG" && (
                      <TabPane tabId="8">
                        <Row className="gy-4">
                          <Col sm="3">
                            <div className="form-group">
                              <label className="form-label">Average RSSI Value</label>
                              <div className="form-control-wrap">
                                <input
                                  ref={register({ required: true })}
                                  className="form-control"
                                  type="text"
                                  id="beverage_averagerssivalue"
                                  name="beverage_averagerssivalue"
                                  onChange={(e) => onInputChange(e)}
                                  value={formData?.beverage_averagerssivalue}
                                />
                                {errors.beverage_averagerssivalue && (
                                  <span className="invalid">This field is required</span>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col sm="3">
                            <div className="form-group">
                              <label className="form-label">Beverage Tx Power</label>
                              <div className="form-control-wrap">
                                <input
                                  ref={register({ required: true })}
                                  className="form-control"
                                  type="text"
                                  id="beverage_tx_power"
                                  name="beverage_tx_power"
                                  onChange={(e) => onInputChange(e)}
                                  value={formData?.beverage_tx_power}
                                />
                                {errors.beverage_tx_power && <span className="invalid">This field is required</span>}
                              </div>
                            </div>
                          </Col>
                          <Col sm="3">
                            <div className="form-group">
                              <label className="form-label">Beverage Distance</label>
                              <div className="form-control-wrap">
                                <input
                                  ref={register({ required: true })}
                                  className="form-control"
                                  type="text"
                                  id="beverage_distance"
                                  name="beverage_distance"
                                  onChange={(e) => onInputChange(e)}
                                  value={formData?.beverage_distance}
                                />
                                {errors.beverage_distance && <span className="invalid">This field is required</span>}
                              </div>
                            </div>
                          </Col>
                          <Col sm="3">
                            <div className="form-group">
                              <label className="form-label">Beverage Measure Value</label>
                              <div className="form-control-wrap">
                                <input
                                  ref={register({ required: true })}
                                  className="form-control"
                                  type="text"
                                  id="beverage_measure_value"
                                  name="beverage_measure_value"
                                  onChange={(e) => onInputChange(e)}
                                  value={formData?.beverage_measure_value}
                                />
                                {errors.beverage_measure_value && (
                                  <span className="invalid">This field is required</span>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="gy-4">
                          <Col sm="3">
                            <div className="form-group">
                              <label className="form-label">Foreground Feet Start</label>
                              <div className="form-control-wrap">
                                <input
                                  ref={register({ required: true })}
                                  className="form-control"
                                  type="text"
                                  id="beverage_foreground_feet_start"
                                  name="beverage_foreground_feet_start"
                                  onChange={(e) => onInputChange(e)}
                                  value={formData?.beverage_foreground_feet_start}
                                />
                                {errors.beverage_foreground_feet_start && (
                                  <span className="invalid">This field is required</span>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col sm="3">
                            <div className="form-group">
                              <label className="form-label">Foreground Feet End</label>
                              <div className="form-control-wrap">
                                <input
                                  ref={register({ required: true })}
                                  className="form-control"
                                  type="text"
                                  id="beverage_foreground_feet_end"
                                  name="beverage_foreground_feet_end"
                                  onChange={(e) => onInputChange(e)}
                                  value={formData?.beverage_foreground_feet_end}
                                />
                                {errors.beverage_foreground_feet_end && (
                                  <span className="invalid">This field is required</span>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col sm="3">
                            <div className="form-group">
                              <label className="form-label">Background Feet Start</label>
                              <div className="form-control-wrap">
                                <input
                                  ref={register({ required: true })}
                                  className="form-control"
                                  type="text"
                                  id="beverage_background_feet_start"
                                  name="beverage_background_feet_start"
                                  onChange={(e) => onInputChange(e)}
                                  value={formData?.beverage_background_feet_start}
                                />
                                {errors.beverage_background_feet_start && (
                                  <span className="invalid">This field is required</span>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col sm="3">
                            <div className="form-group">
                              <label className="form-label">Background Feet End</label>
                              <div className="form-control-wrap">
                                <input
                                  ref={register({ required: true })}
                                  className="form-control"
                                  type="text"
                                  id="beverage_background_feet_end"
                                  name="beverage_background_feet_end"
                                  onChange={(e) => onInputChange(e)}
                                  value={formData?.beverage_background_feet_end}
                                />
                                {errors.beverage_background_feet_end && (
                                  <span className="invalid">This field is required</span>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="gy-4">
                          <Col sm="6">
                            <div className="form-group">
                              <label className="form-label">ibeacon Beverage Status Android</label>
                              <div className="form-control-wrap">
                                <input
                                  ref={register({ required: true })}
                                  className="form-control"
                                  type="text"
                                  id="ibeacon_beverage_status_android"
                                  name="ibeacon_beverage_status_android"
                                  onChange={(e) => onInputChange(e)}
                                  value={formData?.ibeacon_beverage_status_android}
                                />
                                {errors.ibeacon_beverage_status_android && (
                                  <span className="invalid">This field is required</span>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col sm="6">
                            <div className="form-group">
                              <label className="form-label">ibeacon Beverage Status iOS</label>
                              <div className="form-control-wrap">
                                <input
                                  ref={register({ required: true })}
                                  className="form-control"
                                  type="text"
                                  id="ibeacon_beverage_status_ios"
                                  name="ibeacon_beverage_status_ios"
                                  onChange={(e) => onInputChange(e)}
                                  value={formData?.ibeacon_beverage_status_ios}
                                />
                                {errors.ibeacon_beverage_status_ios && (
                                  <span className="invalid">This field is required</span>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    )}
                  </TabContent>
                </PreviewCard>
                <hr className="preview-hr"></hr>
                <Row className="g-3">
                  <Col lg="7" className="offset-lg-5">
                    <FormGroup className="mt-2">
                      <Button color="primary" size="lg" type="submit">
                        Update
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </form>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={showAuthModal}
          toggle={() => onAuthFormCancel()}
          style={{ zIndex: 5000 }}
          className="modal-dialog-centered"
          size="m"
        >
          <ModalBody>
            <a href="#cancel" className="close">
              {" "}
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onAuthFormCancel();
                }}
              ></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Authentication</h5>
              <form onSubmit={handleSubmit(onAuthSubmit)}>
                <FormGroup>
                  <Label htmlFor="userName" className="form-label">
                    User Name
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      ref={register({ required: true })}
                      className="form-control"
                      type="text"
                      id="userName"
                      name="userName"
                      onChange={(e) => onAuthInputChange(e)}
                      // value={formData?.userName}
                    />
                    {errors.userName && <span className="invalid">This field is required</span>}
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="userPasscode" className="form-label">
                    Password
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      ref={register({ required: true })}
                      className="form-control"
                      type="password"
                      id="userPasscode"
                      name="userPasscode"
                      onChange={(e) => onAuthInputChange(e)}
                      //value={formData?.userPasscode}
                    />
                    {errors.userPasscode && <span className="invalid">This field is required</span>}
                  </div>
                </FormGroup>
                <FormGroup className="mt-2">
                  <Button color="primary" size="lg" type="submit">
                    Submit
                  </Button>
                </FormGroup>
              </form>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default AllVenues;
